.nav {
  background: rgba(118, 118, 128, 0.24);
  border-radius: 8px;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.active {
  background-color: #636366;
  height: 28px;
  width: 150px;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  border-radius: 7px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.lt-btn {
  background: rgba(118, 118, 128, 0);
  height: 28px;
  width: 150px;
  color: white;
  border: none;
  border-radius: 7px;
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}
