
.error {
  font-size: smaller;
  color: red;
}

.hide-show {
  position: absolute;
  right:0px;
  bottom: 10px;
  color: #b6b6b6;
}

.hide-show2 {
  position: absolute;
  right: 30px;
  font-size: 20px;
  color: #b6b6b6;
}

/* //////// bootstrao from css///////// */

.form-control:focus, .form-select:focus {
  color: white;
  background-color: inherit;
  border-color: inherit;
  box-shadow: none;
}
.form-control, .form-select {
  padding: 10px 0px;
  border: none;
  border-bottom: solid 1px #626262;
  outline: none;
  background-color: inherit;
  margin: 0rem;
  font-size: 16px;
  color: #fff;
  border-radius: 0rem;
}

.form-select{
  background-image: url('../../assets/svgs/downarrow.svg');
  background-color: inherit;
}

.form-select.is-invalid:not([multiple]):not([size]){
  background-image: url('../../assets/svgs/downarrow.svg');
}
.form-select.is-invalid, .was-validated .form-select:invalid {
  border-color: #dc3545;
}

.form-select.is-invalid:focus, .was-validated .form-select:invalid:focus {
  border-color: inherit;
  box-shadow: none ;
}

.form-floating>label {
  color: #B1B1B1;
  padding: 1rem 0rem;
  border: 0px;
}
.form-floating>.form-control:not(:placeholder-shown) {
  padding-top: 2.625rem;
  padding-bottom: 1.625rem;
}

.form-floating>.form-control-plaintext~label, .form-floating>.form-control:focus~label, .form-floating>.form-control:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  opacity: .65;
  transform: scale(.85) translateY(-0.5rem) translateX(0rem);
}

.form-floating>.form-select-plaintext~label, .form-floating>.form-select:focus~label, .form-floating>.form-select:not(:placeholder-shown)~label, .form-floating>.form-select~label {
  opacity: .65;
  transform: scale(.85) translateY(-1.5rem) translateX(0rem);
}

.form-floating>.form-control{
  padding: 0rem;
}

.form-floating>.form-control:focus{
  padding-top: 2.625rem;
  padding-bottom: 1.625rem;
}

 .form-floating>.form-select:focus{
  padding-top: 1.625rem;
  padding-bottom: 0rem;
}

.form-select>option {
  color: black;
  background-color: #B1B1B1;
}

.form-select::selection {
  background-color: lightgray;
}

.form-control.is-invalid, .was-validated .form-control:invalid {
background-image: none;
}

.form-control.is-invalid:focus, .was-validated .form-control:invalid:focus {
  border-color: inherit;
  box-shadow: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: white;
  caret-color: white;
} 
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus,
select:-webkit-autofill:focus {
  border-bottom-color: #e9ecef;
  caret-color: white;
}
input::selection {
  color: #fff;
  background-color: grey;
}

.back_arrow
{
  margin-right: 13px;  
  width: 40px;
  height: 40px;
  cursor: pointer;
}

/* Styling Checkbox Starts */
input[type='checkbox'] {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  /* Remove most all native input styles */
  appearance: none;
  /* For iOS < 15 */
  background-color: var(--form-background);
  /* Not removed via appearance */
  margin: 0;

  font: inherit;
  color: currentColor;
  width: 20px;
  height: 20px;
  border-radius: 3px;
  transform: translateY(-0.1em);
  border: 1px solid rgba(177, 177, 177, 1);
  display: inline-grid;
  place-content: center;
}

input[type='checkbox']::before {
  content: '';
  width: 0.85em;
  height: 0.85em;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
  transform: scale(0);
  transform-origin: bottom left;
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--form-control-color);
  /* Windows High Contrast Mode */
  background-color: white;
}

input[type='checkbox']:checked::before {
  transform: scale(1);
}

input[type='checkbox']:checked {
  /* background: #284eda; */
  border: 1px solid rgba(177, 177, 177, 1);
}

/* input[type="checkbox"]:focus {
    outline: max(2px, 0.15em) solid currentColor;
    outline-offset: max(2px, 0.15em);
  } */

input[type='checkbox']:disabled {
  --form-control-color: var(--form-control-disabled);

  color: var(--form-control-disabled);
  cursor: not-allowed;
}

.remember_label {
  margin-left: 10px;
  font-size: 16px;
  color: white;
}
