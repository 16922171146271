.policy_wrapper {
  padding: 20px 40px;
}

.custom-pre{
  white-space: pre-wrap;
  color: white;
  font-weight: 400;
  font-size: 16px;
  Line-height:21px;
  font-family: 'Roboto';
}