.modal_head_heading{
    display: flex;
    flex-direction: row;
    padding: 2rem 0;
    align-items: center;
    justify-content: space-between;
  }
  .filtershead{
    font-size: 34px;
    font-weight: 600;
  }
  .resetfilters{
    font-size: 16px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.5);
  }
  .selectdiv{
    display: flex;
    flex-direction: column;
  }