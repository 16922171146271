.modal_btn_style1{
    margin: 0.5rem 2.5rem;
}
.match_main_div{
    display: flex;
    flex-direction: row;
    border-radius: 22px;
    height: max-content;
    background: rgba(56, 63, 78, 1);
    padding: 5px 15px;
    align-items: center;
    margin-top: 1rem;
    justify-content: space-between;
}
.match_outer_div{
    display: flex;
    flex-direction: column;
    margin: 0rem 3rem;
    color: white;
}
.no_display{
    display: none;
}
.bank_card_div1{
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.bank_card_head1{
    border-top: 2px solid rgba(255, 255, 255, 0.08);
    border-bottom: 2px solid rgba(255, 255, 255, 0.08);
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
}