body {
  margin: 0;
  padding: 0;
}
.sidebar-container {
  /* min-height: 100vh; */
  position: relative;
  width: 325px;
  background: #000000;
  transition: 0.7s;
  z-index: 9;
}
.sidebar-logo {
  height: 200px;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sidebar-profile {
  height: 95px;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding-right: 35px;
}
.profile-selected {
  background: #1C2028;
}
.sidebar-menues {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.m1 {
  padding: 0.5rem;
}
.menue-list {
  text-align: center;
  display: flex;
  cursor: pointer;
  width: 165px;
}
.m1 div {
  display: flex;
  align-items: center;
  font-weight: 500;
}
.fa-home {
  color: white;
  font-size: 24px;
}
.d-text {
  color: white;
  margin-left: 2.5rem;
  font-size: 20px;
}
.sidebar-wapper {
  display: flex;
  background-color: black;
  height: 100vh;
  overflow: auto;
  min-width: max-content;

}
.online-indicator {
  width: 8px;
  height: 8px;
  background-color: #21d803;
  border-radius: 100%;
  position: relative;
  top: 36px;
  left: -10px;
}
.p1 {
  display: flex;
}

.profile-content {
  max-width: 90%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  margin-top: 25px;
}

.hamburger-icon {
  display: none;
}
.cross-icon {
  display: none;
}
.m-item2 {
  margin-top: 15px;
}

.m-text {
  color: #717273;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: lightgray;
  margin-top: 8px;
}
.b-circle {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: blue;
  margin-top: 8px;
  display: none;
}

.sidebar-close-icon{
  position: absolute;
  top: 20px;
  right: 11px;
  display: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
}



/* ----responsivenes----- */
@media (max-width: 1200px) {
  .sidebar-container {
    width: 350px;
  }
}
@media (max-width: 992px) {
  .sidebar-container {
    width: 300px;
  }
}
@media (max-width: 835px) {
  .sidebar-container {
    position: absolute;
    z-index: 9999;
  }
  .sidebar-close-icon{
    display: inherit;
  }
  .sidebar-logo img{
    width: 230px;
  }
  .sidebar-is-closed {
    transform: translateX(-30em);
  }
  .sidebar-logo {
    height: 150px;
    margin-top: 2rem;
  }
  .m-item2 {
    margin-top: 15px;
  }
}

@media (max-width: 470px) {
  .sidebar-container {
    width: 250px;
  }
  .sidebar-logo img{
    width: 200px;
  }
  .sidebar-logo {
    height: 120px;
    margin-top: 3rem;
  }
  .sidebar-profile {
    height: 0px;
  }
}



/* ////////////// my side bar //////////////// */

.media-img
{
  width: 57px;
  height: 57px;
  object-fit: cover;
  align-self: center;
}
.media-body{
  display: flex;
  flex-direction: column;
  margin-left: 1.5rem;
  line-height: 1;
  text-transform: capitalize;
}