.options_main{

    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: center;
    border-bottom: 2px solid black;
    padding: 24px 40px;
    cursor: pointer;
}
.option_name{
    font-size: 20px;
    font-weight: 500;
}

.modal-backdrop {
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.modal-header {
    padding: 0rem 2rem !important;
    justify-content: end;
}

.modal-content {
    border: 0 solid rgba(0, 0, 0, 0.2);
    border-radius: 24px;
    /* box-shadow: 0 3px 6px #dadada; */
    background: rgba(28, 32, 40, 1);
    color: white;
  }
  

  .modal__body {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 15px;
    text-align: center;
    margin: 0rem 2rem;
  }
  
  .m-btns {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between !important;
    margin-top: 25px;
  }
  .c-btn Button {
    width: 160px;
    height: 40px;
    border-radius: 25px;
    border: 1px solid #284eda;
    background-color: white;
    color: #284eda;
  }
  .y-btn Button {
    width: 160px;
    height: 40px;
    border-radius: 25px;
    border: 1px solid #284eda;
    background-color: #284eda;
    color: white;
    margin-left: 25px;
  }
  .m-log {
    margin-bottom: 3.5rem;
    font-size: 34px;
    font-weight: 600;
  }
  .m-confirm {
    margin: 26px 0px;
    cursor: pointer;
  }
  .modal-header .close {
    position: relative;
    left: 15px;
    background-color: inherit;
    border: 0px;
    color: white;
    font-size: 27px;
  }

  .modal-backdrop {
    background-color: #f2f2f2;
  }
  
  .modal-header {
    border-bottom: 0px solid #dee2e6;
    padding: 0rem 2rem;
  }
  
  .modalTitle {
    color: #242424;
    font-weight: 500;
  }
  
  hr {
    box-sizing: content-box;
    width: 100%;
    overflow: visible;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  
  hr.new3 {
    border-top: 1px solid #e9ecef;
  }
  