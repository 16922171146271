.mainDiv{
    display: flex;
    width: 100%;
    min-height: 100vh;
    background-color: #000;
}

.sign-in {
  position: relative;
  background: #101217;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  flex-direction: column;
  flex: 1;
  padding-top: 120px;
  padding-bottom: 5rem;
}

.padding-120{
  padding-top: 120px;
}
.sign-in span a {
  align-self: auto;
}

.sign-in h1 {
  font-weight:600;
  font-size: 34px;
  margin-bottom: 30px;
  margin-top: 0rem;
  color: #fff;
}

.sign-in form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 25vw;
}

input[type="submit"], button[type="submit"] {
  background-color: #fff;
  color: #000;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  border: none;
  transition: all 0.3s ease;
  border-radius: 100px;
}

input[type="submit"]:hover, input[type="submit"]:focus, input[type="submit"]:active, button[type="submit"]:hover,
button[type="submit"]:focus, button[type="submit"]:active {
  background-color: #fff;
  color: #000;
}

a {
  color: #424242;
  text-decoration: none;
  align-self: flex-end;
  font-size: 14px;
}

a:hover {
  text-decoration: underline;
}

.input-wrapper.more-action {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    margin: 1rem 0rem;
}

.input-wrapper.more-action a {
    /* margin: 0; */
    font-size: 16px;
    color: white;
}
  
.login_text {
    font-weight: 500;
    text-align: center;
    font-size: 14px;
    color: white;
}

.login_text a {
    font-weight: 600;
    font-size: 14px;  
    padding-left: 5px;
    color: white;
}

.mute-text{

  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.23999999463558197px;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
}

@media screen and (max-width: 992px) {
    .sign-in form {
        width: 45vw;
    }
  }

@media screen and (max-width: 786px) {
  .sign-in form {
    width: 75vw;
  }
  .sign-in{
    padding-top: 50px;
    max-height: 80vh;
    overflow-y: auto;
  }
  .mainDiv{
    flex-direction: column;
  }
  input[type="submit"], button[type="submit"] {
    width: 100%;
  }
}