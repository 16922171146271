.footer {
  display: flex;
  justify-content: center;
  color: rgb(255, 255, 255);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin-bottom: 1rem;
}

.footer h1 {
  font-size: 30px;
  font-weight: 700;
}
.footer p {
  font-size: 20px;
  font-weight: 400;
}

.footer .logo {
  width: 135px;
}

.footer-social {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 35px;
  margin-bottom: 20px;
}

.footer-social img {
  margin-right: 15px;
}

.copyrights {
  font-size: 14px;
  font-weight: 400;
  float: right;
}

.footer-links ul {
  padding: 10px 0px;
}

.footer-links ul li {
  list-style-type: none;
}

.footer-links ul li a {
  color: white;
  font-size: 20px;
  font-weight: 400;
}

.footer-links-login-page {
  padding: 5px 0;
}
.footer-links-login-page a {
  color: rgb(190, 190, 190);
  padding: 0 5px;
}

.footer-links-login-page a:hover {
  color: #fff;
}
@media screen and (max-width: 786px) {
  .footer {
    position: relative;
  }
}