

.file-upload-input {
  /* position: absolute;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  outline: none;
  opacity: 0;
  cursor: pointer; */
  display: none;
}

.image-upload-wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  border-radius: 24px;
  height: 162px;
  width: 100%;
}

/* .image-dropping,
.image-upload-wrap:hover {
  background-color: #1FB264;
  border: 4px dashed #ffffff;
} */

.image-title-wrap {
  padding: 0 15px 15px 15px;
  color: #222;
}

.drag-text{
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 16px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
}

.drag-text .img-margin{
  margin-bottom: 20px;
}

.file-upload-content {
  text-align: center;
  position: relative;

}

.file-upload-image {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.7) 100%);
  border-radius: 24px;
  height: 162px;
  width: 100%;
  object-fit: cover;
  opacity: 0.7;
}
.h-272{
  height: 272px;
}

.file-upload-content .change-text
{
  position: absolute;
  color: #fff;
  left: 50%;
  transform: translate(-50px, 0px);
  bottom: 15px;
  cursor: pointer;
}