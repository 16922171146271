.main_instruct_div{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 710px;
}
.layer1{
    background: #13161C;
    position:absolute;
    height: 331px;
    width: 293px;
    top: 0;
    border-radius: 24px;
}
.layer2{
    position: absolute;
    height: 386px;
    width: 341px;
    top:20px;
    border-radius: 24px;
    background: #181B22;
}
.layer3{
    position: absolute;
    height: 396px;
    width: 397px;
    top: 40px;
    border-radius: 24px;
    background: #1C2028;
}

.circle-inst{
    height: 55px;
    width: 55px;
    border-radius: 50%;
    background: #2C323E;
}

.line-inst{
    height: 18px;
    width: 243px;
    border-radius: 24px;
    background: #2C323E;
    margin-left: 15px;
}

.layer3-main{

    padding: 20px;
}

.layer3-section1{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.layer3-box{

    height: 190px;
    width: 343px;
    background: #2C323E;
    border-radius: 24px;
    margin: 25px 0px;
}

.layer3-buttons{

    width: 125px;
    height: 49px;
    border-radius: 24px;
    background: #2C323E;
    border-radius: 24px;
}

.inst-actions{
    position: absolute;
    bottom: -120px;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0px 60px;
    width: 100%;
}
.inst-actions button {
    width: 100%;
}

@media screen and (max-width: 786px) {
    .main_instruct_div{
        min-height: 600px;
    }
    .layer1{
        height: 240px;
        width: 60%;
    }
    .layer2{
        height: 260px;
        width: 80%;
    }
    .layer3{
        height: 280px;
        width: 95%;
    }
    .layer3-box{
        height: 110px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 15px 0px;
    }
    .layer3-buttons{
        height: 45px;
    }
  }