
  

  /* ///////  LOADER CSS */
  #overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background: #2c2c2c;
    opacity: 0.8;
    z-index: 99999;
  }
  
  .loader {
    position: absolute;
    border: 7px solid #f3f3f3;
    border-radius: 50%;
    border-top: 7px solid #3498db;
    width: 50px;
    height: 50px;
    left: 50%;
    top: 40%;
    animation: spin 2s linear infinite;
    -webkit-animation: spin 2s linear infinite; /* Safari */
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }