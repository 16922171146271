.main__wrapper {
  display: flex;
  background-color: black;
  height: 100vh;
}
.content__wrapper {
  width: 100%;
  background: #101217;
  height: 100vh;
}

/* layout for pages inside sidebar and header */

/* End layout for pages inside sidebar and header */

@media (min-width: 1700px) {
  .routes_wrapper {
    left: 288px;
  }
}
@media (max-width: 768px) {
  .routes_wrapper {
    left: 200px;
  }
}
@media (max-width: 570px) {
  .routes_wrapper {
    top: 120px;
  }
  .sidebar-profile {
    height: 120px;
  }
}

@media (max-width: 470px) {
  .routes_wrapper {
    left: 20px;
    top: 150px;
    max-width: 90%;
  }
}
