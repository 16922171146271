.profile_main_div{
    display: flex;
    justify-content: center;
    padding: 0rem 2rem;
}
.profile-card{
    min-width: 600px;
    background: rgba(28, 32, 40, 1);
    flex-direction: column;
    padding: 33px;
    border-radius: 20px;
    height: max-content;

}

.profile-card form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

.profile_social{
    background-color: #252931;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 24px;
    padding: 0rem 1rem;
    cursor: pointer;
}
.social-left{
    height: 65px;
    justify-content: center;
    align-items: center;
    display: flex;
}
.wallet-left{
    height: 65px;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    line-height: 1.2;
    justify-content: center;
}
.social-button{
    width: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    height: 40px;
    justify-content: center;
    background-color: white;
    color: black;
    font-size: 16px;
    font-weight: 500;
    border-radius: 100px;
    cursor: default;
}

.cursor_default{
    cursor:default;
}
.deselect_select{
    background-image: none;
    pointer-events: none;
    border-bottom: 0px;
}
.deselect_input{
    pointer-events: none;
    border-bottom: 0px;
}

/* social links page */
.social_main {
    padding: 24px 40px;
}
.social-bg{
    background-color: rgba(255, 255, 255, 0.04);
}
.social-img{
    width: 41px;
    height: 41px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add-round{
    width: 56px;
    height: 56px;
    background: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    float: right;
    cursor: pointer;
}

.social-form{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
/* ----responsivenes----- */
@media (max-width: 1200px) {
    .profile-card{
        min-width: 500px;
    }
}
@media (max-width: 992px) {
    .profile-card{
        min-width: 450px;
    }
}
@media (max-width: 835px) {
    .profile-card{
        min-width: 100%;   
    }
    .profile_main_div{
        padding: 0rem 2rem;
    }
}

@media (max-width: 540px) {
    .profile-card{
        background: none;        
    }
    .profile_main_div{
        padding: 0rem 0rem;
    }
}