.leftSide {
  width: 375px;
  background: #000000;
}
.leftSide img {
  position: sticky;
  top: 0px;
}
.pt-20 {
  /* padding-top: 20px; */
}
@media screen and (max-width: 992px) {
  .leftSide {
    width: 350px;
  }
}

@media screen and (max-width: 786px) {
  .leftSide {
    width: 100%;
    height: 20vh;
  }
  .leftSide img {
    height: 129px;
  }
}