.open_chat {
  display: flex;
  flex-direction: column;
  border-left: 1px solid black;
  width: calc(100% - 325px);
  height: 90vh;
  position: relative;
  z-index: 100;
}

.chat_div {
  border-bottom: 1px solid black;
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 1rem;
  align-items: center;
  position: relative;
  z-index: 1000;
}

.MsgsArea {
  padding: 1.5rem;
  color: white;
  overflow-y: auto;
  /* height: 62.5vh; */
  position: relative;
  z-index: 1;
}

.msgsender1 {
  background-color: rgba(56, 63, 78, 1);
  border-radius: 16px 16px 16px 0px;
  padding: 1rem;
  max-width: 90%;
  width: max-content;
  font-size: 14px;
  font-weight: 500px;
}

.msgChatStart {
  font-size: 12px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-profile {
  height: 65px;
  align-items: center;
  display: flex;
  cursor: pointer;
  padding: 0 5%;
  width: 100%;
}

.msgsender2 {
  background-color: rgba(28, 32, 40, 1);
  border-radius: 16px 16px 0px 16px;
  padding: 1rem;
  max-width: 90%;
  width: max-content;
  font-size: 14px;
  font-weight: 500px;
  float: right;
}

.chat-search {
  width: 250px;
  height: 33px;
  border: 1px solid rgba(209, 242, 255, 0.2);
  border-radius: 100px;
  display: flex;
  align-items: center;
  padding: 3px;
  margin: 1.5rem;
}

.chat-sidebar {
  background-color: rgb(16, 18, 23);
  width: 325px;
  overflow: auto;
}

::-webkit-scrollbar {
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Works on Firefox ScrollBar */
* {

  scrollbar-width: thin;
  scrollbar-color: rgba(136, 136, 136, 0.3) rgba(136, 136, 136, 0.16);
}

.small_font {
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.msgtime {
  color: rgba(177, 177, 177, 1);
  font-size: 12px;
  font-weight: 400;
}

.lastmsgtime {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.media-body1 {
  margin-left: 0.7rem;
  width: 82%;
}

.back_screen {
  display: none;
}

@media (max-width: 735px) {
  .chat-sidebar {
    width: 100%;
  }

  .search_main_div {
    width: 100%;
    padding: 1rem;
  }

  .chat-search {
    margin: 0;
    width: 100%;
  }

  .display_none {
    display: none;
  }

  .back_screen {
    padding-right: 5px;
    height: 30px;
    width: 30px;
    display: flex;
  }
}