.dot{
    background-color: white;
    width: 10px;
    height: 10px;
    margin: 5px;
    border-radius: 100%;
    color: white;
}
.company_card_div{
    background: rgba(118, 118, 128, 0.24);
    margin-top: 1rem;
    display: flex;
    align-items: center;
    border-radius: 30px;
    flex-direction: column;
}
.company_card_div1{
    background: rgba(118, 118, 128, 0.24);
    margin: 1rem 0rem 0rem 0rem;
    width: 80vh;
    height: 80vh;
    display: flex;
    align-items: center;
    border-radius: 30px;
    flex-direction: column;
}
.comaign_likes{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
}
.compaign_chat_outer{
    display: flex;
    flex-direction: column;
    margin: 2rem 0rem 0rem 1rem;
    color: white;
}
.compaign_main_div{
    display: flex;
    flex-direction: row;
    height: 60px;
    padding: 15px 15px 15px 0px;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid black;
}
.comp_chat_logo_div{
    background-color: white;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.comp_chat_logo_div .img-user {
    max-width: 100%;
    height: 100%;
    border-radius: 50%;
}

.link_div{
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.75);
    text-decoration: underline;
    margin-left: 5px;
}
.link_class{
    align-self: flex-start!important;
}
.compaignshowdiv{
    width: 50%;
    border-right: 1px solid black;
}
.compaignnotshowdiv{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.showlikes{
    width: 50%;
}
.shownotlikes{
    display: none;
}
.plusdiv{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: white;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 3%;
    align-items: center;
    right: 2%;
}
.plusdiv1{
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background: white;
    font-size: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 3%;
    align-items: center;
    right: 40%;
}
.addcompaignhead{
    font-size: 28px;
    font-weight: 500;
}
.inputdivs{
    display: flex;
    flex-direction: column;
    margin: 1rem 0;
}
.modalinput{
    border: none;
    outline: none;
    background: transparent;
    border-bottom: 1px solid grey;
    margin-bottom: 1rem;
}
.modalcancel{
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    background: transparent;
    border: none;
    outline: none;
}
.modalcancel:hover{
    color: black;
    background: white;
    border-radius: 100px;
    padding: 10px;
    font-size: 16px;
    font-weight: 500;
}
.modalcancel:focus{
   box-shadow: none!important;
}