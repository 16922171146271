@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Roboto'), url(./assets//fonts/Roboto-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: local('Roboto'), url(./assets/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: local('Roboto'),
    url(./assets/fonts/Roboto-Medium.ttf) format('truetype');
}
@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: local('Roboto'), url(./assets/fonts/Roboto-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  font-weight: 900;
  src: local('Roboto'),
    url(./assets/fonts/Roboto-Black.ttf) format('truetype');
}

html,
body {
  margin: 0;
  font-family: 'Roboto' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  overflow-x: hidden;
}

code {
  font-family: 'Roboto' !important;
}

.privary-terms {
  padding: 20px 40px;
  width: 100%;
  color: white;
}